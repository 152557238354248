.container.contact_container {
    width: 58%;
    display: grid;
    grid-template-columns: 30% 50%;
    gap: 12%;
}

.contact_options {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
}

.contact_option h5 {
    word-wrap: break-word;
    /* Ensures long words or text breaks to the next line */
    word-break: break-all;
    /* Breaks long words at any point */
    white-space: normal;
    /* Allows text to wrap to the next line */
}


.contact_option {
    background: var(--color-bg-match);
    padding: 1.2rem;
    border-radius: 1.2rem;
    text-align: center;
    border: 1px solid transparent;
    transition: var(--transition);
}

.contact_option:hover {
    background: transparent;
    border-color: var(--color-primary-match);
}

.contact_option-icon {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
}

.contact_option a {
    margin-top: 0.7rem;
    display: inline-block;
    font-size: 0.8rem;
}

/* ========== FORM ==========  */

form {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
}

input,
textarea {
    width: 100%;
    padding: 1.5rem;
    border-radius: 0.5rem;
    background-color: transparent;
    border: 2px solid var(--color-primary-match);
    resize: none;
    color: var(--color-white);
}


/* ========== MEDIA QUERIES (MEDIUM DEVICES)========== */
@media screen and (max-width:1024px) {
    .container.contact_container {
        grid-template-columns: 1fr;
        gap: 2rem;
    }
}


/* ========== MEDIA QUERIES (SMALL DEVICES)========== */
@media screen and (max-width:600px) {

    .container.contact_container {
        width: var(--container-width-sm);
    }
}