#experience {
    text-align: center;
    margin-bottom: 2rem;
}

.experience_container {
    width: 50%;
    display: grid;
    grid-template-columns: repeat(2, 2fr);
    gap: 4rem;
}

.experience {
    text-align: center;
    background: var(--color-bg-match);
    padding: 2rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition);
}

.experience:hover {
    background: transparent;
    border-color: var(--color-primary-match);
    cursor: default;
}

.experience p {
    text-align: justify;
}

.experience h3 {
    margin-bottom: 1rem;
    color: var(--color-primary);
}

.experience p {
    margin-bottom: 1rem;
}



/* ========== MEDIA QUERIES (MEDIUM DEVICES)========== */
@media screen and (max-width:1024px) {

    .experience_container {
        width: 90%;
    }

    .experience {
        padding: 2.5rem 2rem;
        margin: 0 auto;
    }

    .experience h3 {
        margin-bottom: 2rem;
    }

    .experience p {
        margin-bottom: 2rem;
    }
}


/* ========== MEDIA QUERIES (SMALL DEVICES)========== */
@media screen and (max-width:600px) {

    .experience_container {
        grid-template-columns: repeat(1, 1fr);
        gap: 2rem;
        width: 90%;
    }

    .experience {
        padding: 3rem 1rem;

    }
}