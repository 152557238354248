#about {
    text-align: center;
}

.about_container {
    display: grid;
    grid-template-columns: 35% 50%;
    gap: 15%;
}


/* ========== About ME =========  */
.about_me {
    aspect-ratio: 3/4;
    border-radius: 2rem;
    background: linear-gradient(45deg, transparent, var(--color-primary), transparent);
    display: grid;
    place-items: center;
}

.about_me img {
    aspect-ratio: 3/4;
}

.about_me-image {
    border-radius: 2rem;
    overflow: hidden;
    transform: rotate(10deg);
    background: linear-gradient(35deg, rgba(77, 181, 255, 0.8), var(--color-primary), rgba(77, 181, 255, 0.9));
    transition: var(--transition);
}

.about_me-image:hover {
    transform: rotate(0deg);
}


/* ========== About Cards ==========  */
.about_cards {
    justify-content: center;
    display: flex;
    /* grid-template-columns: repeat(3, 1fr); */
    gap: 2rem;
}

.about_card {
    background: var(--color-bg-match);
    border: 1px solid transparent;
    border-radius: 1rem;
    padding: 2rem;
    text-align: center;
    transition: var(--transition);
}

.about_card:hover {
    background: transparent;
    border-color: var(--color-primary-match);
    cursor: default;
}

.about_icon {
    color: var(--color-primary);
    font-size: 1.4rem;
    margin-bottom: 1rem;

}

.about_card h5 {
    font-size: 0.95rem;
}

.about_card small {
    font-size: 0.7rem;
    color: var(--color-light);
}

.about_contents p {
    margin: 2rem 0 2.6rem;
    color: var(--color-light);
}

/* ========== MEDIA QUERIES (MEDIUM DEVICES)========== */
@media screen and (max-width:1024px) {
    .about_container {
        grid-template-columns: 1fr;
        gap: 0;
    }

    .about_me {
        width: 50%;
        margin: 2rem auto 4rem;
    }

    .about_contents p {
        margin: 1rem 0 1.5rem;
    }
}


/* ========== MEDIA QUERIES (SMALL DEVICES)========== */
@media screen and (max-width:600px) {

    .about_me {
        width: 65%;
        margin: 0 auto 3rem;
    }

    .about_cards {
        /* grid-template-columns: 1fr 1fr;
        gap: 1rem; */
        flex-direction: column;
    }

    .about_contents {
        text-align: center;
    }

    .about_contents p {
        margin: 1.5rem 0;
    }
}